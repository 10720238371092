









































import { Component, Vue } from 'vue-property-decorator';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
} from '@/interfaces';
import { dispatchGetUsers, dispatchCreateUser } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = '';
  public email: string = '';
  public birthday: Date | null = null;
  public membershipNumber: string = '';
  public mainUserId: number | null = null;
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.reset();
  }

  public reset() {
    this.password1 = '';
    this.password2 = '';
    this.fullName = '';
    this.email = '';
    this.birthday = null;
    this.membershipNumber = '';
    this.mainUserId = null;
    this.isActive = true;
    this.isSuperuser = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const createdProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        createdProfile.full_name = this.fullName;
      }
      if (this.email) {
        createdProfile.email = this.email;
      }
      if (this.birthday) {
        createdProfile.birthday = this.birthday;
      }
      if (this.mainUserId) {
        createdProfile.main_user_id = this.mainUserId;
      }
      createdProfile.membership_number = this.membershipNumber;
      createdProfile.is_active = this.isActive;
      createdProfile.is_superuser = this.isSuperuser;
      createdProfile.password = this.password1;
      await dispatchCreateUser(this.$store, createdProfile);
      this.$router.push('/main/admin/users');
    }
  }
}
